import { Circle, Mic, Pause, PlayArrow, QuestionMarkTwoTone, RecordVoiceOver, RestartAlt, Save, Share, Stop } from '@mui/icons-material';
import { Box, Card, CardActions, CardContent, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import { centered_flex_box } from './Styles';
import { isAndroid, isChrome, isIOS, isMobile, isSafari } from 'react-device-detect';
import { Tour } from 'antd';

function getSupportedMimeTypes(media, types, codecs) {
    const isSupported = MediaRecorder.isTypeSupported;
    const supported = [];
    types.forEach((type) => {
        const mimeType = `${media}/${type}`;
        if (isSupported(mimeType))
            supported.push({ mimeType, extension: type });
    });
    return supported;
};

function RecordAudioCard({ addAudioElement, filesLength }) {

    let mimeType = "audio/webm";
    const audioTypes = ["webm", "ogg", "mp3", "mp4", "wav", "x-matroska"];
    const supportedAudios = getSupportedMimeTypes("audio", audioTypes, []);
    mimeType = supportedAudios[0].mimeType;

    const {
        startRecording,
        stopRecording,
        togglePauseResume,
        recordingBlob,
        isRecording,
        isPaused,
        recordingTime,
        mediaRecorder
    } = useAudioRecorder({
        noiseSuppression: true,
        echoCancellation: true
    }, undefined, { mimeType });


    const handleDone = () => {
        stopRecording();
        const file = new File([recordingBlob], `AudioRecording_${filesLength + 1}.${supportedAudios[0].extension}`, { type: mimeType });
        addAudioElement([file]);
    }

    const ref1 = React.useRef(null);
    const ref2 = React.useRef(null);
    const ref3 = React.useRef(null);
    const ref4 = React.useRef(null);
    const ref5 = React.useRef(null);
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState(1);
    const steps = [
        {
            title: 'Start Recording',
            description: 'Click the record button to start speaking',
            placement: "bottom",
            nextButtonProps: {
                onClick: () => setStep(2)
            },
            target: () => ref1.current,
        },
        {
            title: 'Speak',
            description: 'Say all you like',
            nextButtonProps: {
                onClick: () => setStep(3)
            },
            target: () => ref2.current,
        },
        {
            title: 'Stop',
            description: 'Click here to stop recording',
            nextButtonProps: {
                onClick: () => setStep(4)
            },
            target: () => ref3.current,
        },
        {
            title: 'Save',
            description: 'Then click here to add the recording to uploads',
            nextButtonProps: {
                onClick: () => setStep(5)
            },
            target: () => ref4.current,
        },
        {
            title: 'Restart',
            description: `Or click here to restart recording`,
            nextButtonProps: {
                onClick: () => setStep(1)
            },
            target: () => ref1.current,
        }
    ];

    return (
        <Box width="100%">
            <Card>
                <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
                <CardContent sx={{ ...centered_flex_box, flexDirection: "column" }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <Typography textAlign="center" variant='h6'>Record Audio</Typography>
                        <IconButton onClick={() => {setOpen(true); setStep(1)}}><QuestionMarkTwoTone /></IconButton>
                    </Box>
                    {(isRecording || recordingBlob) && <Typography textAlign="center"><Circle sx={{ color: isPaused ? "black !important" : "red !important", width: 0.05, mx: 1 }} />{moment.utc(moment.duration(recordingTime, 'seconds').asMilliseconds()).format("mm:ss")}</Typography>}
                    {isRecording && mediaRecorder && <LiveAudioVisualizer
                        ref={ref2}
                        mediaRecorder={mediaRecorder}
                        barColor="var(--secColor)"
                        width={200}
                        height={75}
                    />}
                </CardContent>
                <CardActions>
                    {!isRecording && <IconButton ref={ref1} onClick={() => startRecording()}><Mic sx={{ color: "var(--secColor)" }} /></IconButton>}
                    {(isRecording || open) && <IconButton ref={ref3} onClick={stopRecording}><Stop sx={{ color: "var(--secColor)" }} /></IconButton>}
                    {isRecording && <IconButton onClick={togglePauseResume}>{isPaused ? <PlayArrow sx={{ color: "var(--secColor)" }} /> : <Pause sx={{ color: "var(--secColor)" }} />}</IconButton>}
                    {(recordingBlob || open) && <IconButton ref={ref4} onClick={handleDone}><Save sx={{ color: "var(--accColor)" }} /></IconButton>}
                </CardActions>
            </Card>
        </Box>
    )
}

export default RecordAudioCard;
