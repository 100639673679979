import { Celebration, Diamond, Email, ExpandMore, Facebook, Phone, Send, Upload, WhatsApp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Dialog, Grid, Typography } from "@mui/material";
import { MainInput, centered_flex_box, main_button, right_flex_box } from "./Styles";
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Image, notification } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import MuiPhoneNumber from "material-ui-phone-number";
import ReactFileReader from "react-file-reader";
import { config } from "../config";
import { uploadFile } from "react-s3";
import { orderPriceList } from "../store/actions/usersActions";
import moment from "moment";
import CheckoutModal from "./CheckoutModal";
import { useNavigate } from "react-router-dom";

function BundleCard({ bundle, user, abroad }) {

    const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
    const navigate = useNavigate()

    const openCheckout = () => {
        if (user) {
            setIsCheckoutOpen(true)
        } else {
            navigate("/login")
        }
    }

    const closeCheckout = () => {
        setIsCheckoutOpen(false)
    }

    return (
        <Grid
            item
            key={bundle.name}
            xs={isMobile ? 12 : 6}
        >
            <CheckoutModal bundle={bundle} open={isCheckoutOpen} handleClose={closeCheckout} abroad={abroad} price={abroad ? bundle.priceExt : bundle.price} />
            <Card>
                <CardHeader
                    title={`${bundle.name} Bundle`}
                    subheader={`Up to ${bundle.maxUsers} guests`}
                    titleTypographyProps={{ align: 'center' }}
                    action={bundle.name === 'Wedding' ? <Diamond /> : (bundle.name === "Prom" ? <Celebration /> : null)}
                    subheaderTypographyProps={{
                        align: 'center',
                    }}
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[200]
                                : theme.palette.grey[700],
                    }}
                />
                <CardContent>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'baseline',
                            mb: 2,
                        }}
                    >
                        <Typography component="h2" variant="h3" color="text.primary">
                            {abroad ? bundle.priceExt : bundle.price}
                        </Typography>
                        <Typography variant="h4" color="text.primary">
                            {" "}EGP
                        </Typography>
                    </Box>
                    {abroad && <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'baseline',
                            mb: 2,
                        }}
                    >
                        <Typography variant="h6" color="text.secondary">
                            ~ {(bundle?.priceExt / 50).toFixed(2)} USD
                        </Typography>
                    </Box>}
                    <ul>
                        <Typography
                            component="li"
                            variant="subtitle1"
                        >
                            Private QR Code & Link With Pin
                        </Typography>
                        <Typography
                            component="li"
                            variant="subtitle1"
                        >
                            Unlimited Photos & Guestbook Messages
                        </Typography>
                        {bundle.video && <Typography
                            component="li"
                            variant="subtitle1"
                        >
                            Unlimited Videos
                        </Typography>}
                        {bundle.voiceMessage && <Typography
                            component="li"
                            variant="subtitle1"
                        >
                            Unlimited Voice Messages
                        </Typography>}
                        <Typography
                            component="li"
                            variant="subtitle1"
                        >
                            Guests Can Keep Uploading for {bundle.uploadPeriod} Days
                        </Typography>
                        <Typography
                            component="li"
                            variant="subtitle1"
                        >
                            We Keep Your Media Secure for <strong>6 months</strong>
                        </Typography>
                    </ul>
                </CardContent>
                <CardActions>
                    <Button sx={{ ...main_button }} onClick={openCheckout} fullWidth >
                        Buy Now
                    </Button>
                </CardActions>
            </Card>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BundleCard);