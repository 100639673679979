import { CancelOutlined, Celebration, Check, CheckCircle, CheckCircleOutline, Close, Diamond } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Container, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { connect } from "react-redux";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getBundles } from '../../app/store/actions/commonActions';
import { centered_flex_box, main_button } from '../../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { CheckoutModal } from '../../app/components';
import BundleCard from '../../app/components/BundleCard';
import axios from 'axios';
import { Space, Table } from 'antd';

export const Pricing = ({ bundles, getBundles, isLoading, user }) => {

    const navigate = useNavigate();
    const { category } = useParams();
    const [abroad, setAbroad] = React.useState(false)
    const [checkout, setCheckout] = React.useState({
        isOpen: false,
        bundle: null
    })

    const { bundle, isOpen } = checkout

    const openCheckout = (selectedBundle) => {
        if (user) {
            setCheckout({
                isOpen: true,
                bundle: selectedBundle
            })
        } else {
            navigate("/login")
        }
    }

    const closeCheckout = () => {
        setCheckout({
            isOpen: false,
            bundle: null
        })
    }

    useEffect(() => {
        getBundles({ category })
        axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_GELOC_KEY}`).then((res) => {
            setAbroad(res.data.country_code2 !== "EG")
        });
    }, [])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            fixed: 'left',
        },
        {
            title: 'Guest limit',
            dataIndex: 'maxUsers',
        },
        {
            title: 'Photos',
            dataIndex: 'photos',
        },
        {
            title: 'Videos',
            dataIndex: 'videos',
        },
        {
            title: 'Voice Messages',
            dataIndex: 'voiceMessages',
        },
        {
            title: 'Price',
            render: (_, { price, priceExt }) => abroad ? `${priceExt} USD` : `${price} EGP`
        },
        {
            title: 'Upload Period',
            dataIndex: 'uploadPeriod',
            render: (_, { uploadPeriod }) => `${uploadPeriod} days`
        },
        {
            title: 'Expires after',
            dataIndex: 'expiry',
            render: (_, { expiry }) => `${expiry} months`
        },
        {
            title: '',
            render: (_, record) => <Space>
                <Button onClick={() => openCheckout(record)} sx={{ ...main_button }}>Buy Now</Button>
            </Space>
        },
    ];

    const data = bundles?.map((row) => {
        return {
            _id: row._id,
            key: row._id,
            name: row.name,
            maxUsers: row.maxUsers,
            uploadPeriod: row.uploadPeriod,
            expiry: row.expiry,
            price: row.price,
            priceExt: row.priceExt / 50,
            photos: <CheckCircleOutline color='green' />,
            videos: row.video ? <CheckCircleOutline color='green' /> : <CancelOutlined color='red' />,
            voiceMessages: row.voiceMessage ? <CheckCircleOutline color='green' /> : <CancelOutlined color='red' />,
        }
    })

    if (isLoading && !bundles) {
        return (
            <Box sx={{ ...centered_flex_box, height: "100vh" }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <>
            <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Packages
                </Typography>
                <Typography gutterBottom={5} variant="h5" align="center" color="text.secondary" component="p">
                    No matter the occasion, we have something just right for you and your memories
                </Typography>
                <Typography align="center">Nothing perfectly fits? <NavLink to="/contact" className={"a2"}>Contact Us!</NavLink></Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="md" component="main" sx={{ mb: 5 }}>
                <Grid container spacing={5} alignItems="flex-end">
                    {bundles?.filter((bundle) => bundle.name === "Wedding").map((bundle) => (
                        // Enterprise card is full width at sm breakpoint
                        <>
                            <Grid
                                item
                                xs={12}
                                sx={{ ...centered_flex_box, flexDirection: "column" }}
                            >
                                <Typography gutterBottom={1} fontWeight={"bold"} variant="h4" align="center" color="text.secondary" component="p">
                                    Best Seller
                                </Typography>
                                <BundleCard bundle={bundle} abroad={abroad} />
                            </Grid>
                        </>
                    ))}
                    {bundles?.filter((bundle) => bundle.name === "Summer").map((bundle) => (
                        // Enterprise card is full width at sm breakpoint
                        <>
                            <Grid
                                item
                                xs={12}
                                sx={{ ...centered_flex_box, flexDirection: "column" }}
                            >
                                <Typography gutterBottom={1} fontWeight={"bold"} variant="h4" align="center" color="text.secondary" component="p">
                                    Season Favorite
                                </Typography>
                                <Typography gutterBottom={1} variant="p" align="center" color="text.secondary" component="p">
                                    No more paying for additional storage, opt for the unlimited solution for your unlimited memories
                                </Typography>
                                <BundleCard bundle={bundle} abroad={abroad} />
                            </Grid>
                        </>
                    ))}
                    <Grid
                        item
                        xs={12}
                        sx={{ ...centered_flex_box, flexDirection: "column" }}
                    >
                        <Typography gutterBottom={1} fontWeight={"bold"} variant="h4" textAlign="center" color="text.secondary" component="p">
                            Other Bundles
                        </Typography>
                    </Grid>
                    {bundles?.filter((bundle) => bundle.name !== "Wedding" && bundle.name !== "Summer").map((bundle) => (
                        <>
                            <Grid
                                item
                                xs={12}
                                sx={{ ...centered_flex_box, flexDirection: "column" }}
                            >
                                <BundleCard bundle={bundle} abroad={abroad} />
                            </Grid>
                        </>
                    ))}
                </Grid>
            </Container>
            <CheckoutModal bundle={bundle} open={isOpen} handleClose={closeCheckout} abroad={abroad} price={abroad ? bundle?.priceExt : bundle?.price} />
            <Box sx={{ ...centered_flex_box, flexDirection: "column", maxWidth: "100vw" }}>
                <Table
                    columns={columns}
                    dataSource={data}
                    scroll={{ x: true }}

                    size='small'
                    showSorterTooltip={{
                        target: 'sorter-icon',
                    }}
                />
            </Box>
        </>
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading,
    bundles: state?.records?.bundles
});

const mapDispatchToProps = { getBundles };

export default connect(mapStateToProps, mapDispatchToProps)(Pricing);